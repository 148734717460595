import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThankYou from "../components/ThankYou/ThankYou"

const ThankYouPage = () => {
  return (
    <Fragment>
      <ThankYou />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Thank you" />
    <ThankYouPage />
  </Layout>
)
